import React from 'react'
import { getDataDependencies } from 'services/api'
import fetchSingleType from 'src/lib/Strapi/fetchSingleType'

import HomeHero from 'components/shared/HomeHero'
import SliceManager from 'components/shared/SliceManager'

const Home = ({ pageData: { homeHero, slices } }) => {
  return (
    <>
      {homeHero && <HomeHero {...homeHero} />}
      {slices && <SliceManager slices={slices} />}
    </>
  )
}

export async function getStaticProps() {
  const data = await fetchSingleType('homepage')

  const extendedData = await getDataDependencies(data)

  return { props: { pageData: extendedData } }
}

export default Home
